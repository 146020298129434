html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.leaflet-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.modal-backdrop.show {
  opacity: 25%;
}

.sr-only {
  display: none;
}

#draw-message-box {
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 999;
  background-color: white;
  padding: 1em;

  h6 {
    font-weight: bold;
    padding: 0.5em 0.5em 0;
  }
}

#draw-import-kml {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 999;
}

.finish-draw-button {
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 999;
}

.accordion-header button {
  padding: 10px;
}

.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex-none {
  flex: none;
}

.form-label {
  font-weight: bold;
}

.leaflet-touch .leaflet-bar a {
  width: 40px;
  height: 40px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -205px 4px !important;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -235px 4px !important;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -25px 5px !important;
}

.leaflet-draw-actions-top.leaflet-draw-actions-bottom a,
.leaflet-touch .leaflet-draw-actions a {
  height: 40px;
  line-height: 40px;
}

.leaflet-touch .leaflet-right .leaflet-draw-actions {
  right: 50px;
}

.leaflet-control-attribution .leaflet-attribution-flag {
  display: none !important;
}

@media (max-width: 800px) {
  .mobile-hidden {
      display: none;
  }
}