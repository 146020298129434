html {
  overflow-y: auto;
  display: block;
  width: auto;
  height: auto;
}

@media print {
  body {
    font-size: smaller;
  }

  img {
    width: 150px !important;
  }

  #content-container {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0;
  }

  .no-break {
    break-inside: avoid;
  }

  .page-break {
    break-after: page;
  }
}
